






































































import {Vue, Component} from 'vue-property-decorator';
import {getPointages} from '@/api/pointage';

import {UserModule} from '../../store/modules/user';
import {ApplicationModule} from '../../store/modules/application';
import {reviveUTCDate, to} from '../../utils';
import {ClockInDayViewModel} from '@/entities/pointage/pointage.types';

@Component({
    name: 'UserPointages',
    components: {},
})
export default class UserPointages extends Vue {
    private today: string = new Date().toISOString().substr(0, 10);

    private focus = '';
    public dayViewModel: ClockInDayViewModel | null = null;
    public offsetTop = 0;

    private mounted() {
        (this.$refs.calendar as any).scrollToTime({hour: 8, minute: 0});
        this.refreshEvent(this.today);
    }

    private updateRange({start}: any) {
        this.today = start.date;
        this.refreshEvent(`${start.date}T00:00:00`);
    }

    public get ZA() {
        return this.dayViewModel?.totalZA ?? 0;
    }

    public get ZR() {
        return this.dayViewModel?.totalZR ?? 0;
    }

    public get ZAR() {
        return this.ZA + this.ZR;
    }

    public get isGrDep() {
        return this.dayViewModel?.isGrDep ? 'Oui' : 'Non';
    }

    public get food() {
        return this.dayViewModel?.totalFoodTicket ?? 0;
    }

    public get events(): any[] {
        if (!this.dayViewModel || !Array.isArray(this.dayViewModel?.events)) {
            return [];
        }

        const now = new Date();
        const timeZoneOffset = now.getTimezoneOffset();

        const tempEvtArr: any[] = [];

        this.dayViewModel.events.forEach((element) => {
            const start = new Date(`${element.start.substr(0, 16)}:00Z`);
            const updatedSt = new Date(start.getTime() - timeZoneOffset * 60000);
            const end = new Date(`${element.end.substr(0, 16)}:00Z`);
            const updatedEnd = new Date(end.getTime() - timeZoneOffset * 60000);
            tempEvtArr.push({
                name: element.name,
                start: updatedSt.toISOString().substr(0, 16),
                end: updatedEnd.toISOString().substr(0, 16),
            });
        });

        return tempEvtArr;
    }

    public get computedDateTitle() {
        const revivedDate = reviveUTCDate(`${this.today}T00:00:00`);
        return this.$i18n.d(revivedDate, 'weekdayStartFull');
    }

    private async refreshEvent(dateStr: string) {
        if (!UserModule.authenticated || !ApplicationModule.online) {
            return;
        }
        const [, data] = await to(getPointages(dateStr));
        if (data) {
            this.dayViewModel = data;
        }
    }

    public onScroll (e: any) {
        this.offsetTop = e.target.scrollingElement.scrollTop;
    }
}
